.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
	margin: -1px -8px -8px 40px;
}
.table-package-list {
	.ant-table {
		margin: 0 -8px 8px 40px;
	}
}

.package-table {
	.ant-table-container table > thead > tr:first-child th:first-child {
		border-top-left-radius: 6px;
	}
	.ant-table-container table > thead > tr:first-child th:last-child {
		border-top-right-radius: 6px;
	}
	.ant-table-thead > tr > th {
		border-bottom: none;
	}
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
			[colspan]
		)::before {
		background: none;
	}
}

.package-timeline-tabs {
	padding-right: 40px;
	padding-left: 15px;
	padding-bottom: 25px;
	padding-top: 0;
	.ant-steps-item {
		&.ant-steps-item-finish,
		&.ant-steps-item-wait {
			.ant-steps-item-tail {
				margin: 0 auto !important;
				bottom: 1px !important;
				top: unset !important;
				&::after {
					height: 0 !important;
					margin-left: 15px !important;
				}
			}
			.ant-steps-item-container {
				display: flex;
				flex-direction: column-reverse;
				justify-content: space-between;
				gap: 2px;
				height: 100%;
				.ant-steps-item-content {
					margin: 0;
					.ant-steps-item-title {
						display: flex;
						align-items: flex-start;
					}
				}
			}
		}
		&.ant-steps-item-finish {
			.ant-steps-item-title {
				span {
					color: #1a1a1a;
				}
			}

			i {
				color: #c7c7c7;
			}

			.ant-steps-item-tail {
				&::after {
					border: 1px solid #c7c7c7;
				}
			}

			&.tail-none {
				.ant-steps-item-tail {
					display: none;
				}
				&.last-tail {
					i {
						// color: #fbb739;
					}
				}
			}
		}

		&.ant-steps-item-wait {
			&.tail-none {
				.ant-steps-item-title > span,
				.description > span {
					color: #bdbdbd;
				}
				.ant-steps-item-tail {
					display: none;
				}
				.ant-steps-item-icon {
					visibility: hidden;
				}
			}
			& + .ant-steps-item-wait {
        .ant-steps-item-icon {
          opacity: 0;
        }
      }
			.ant-steps-item-tail {
				&::after {
					opacity: 0;
				}
			}
		}
	}
	.ant-steps-item-content {
		width: 100% !important;
		text-align: left !important;
	}
	.ant-steps-item-icon {
		margin: 0 !important;
		width: 10px !important;
		height: 10px !important;
	}
}

.table-package-list {
	.ant-table-thead > tr > th {
		// border-bottom: none;
		background: none !important;
	}
	.ant-table-tbody > tr > td.ant-table-cell-row-hover {
		background: none !important;
	}
}
.package-list {
	&.table-expandable {
		&_has-milestone {
			& .ant-table tbody .ant-table-expanded-row td {
				padding-bottom: 8px !important;
				// border-bottom: 1px solid #e5e5e5;
			}
		}
		& .ant-table.ant-table-small tbody .ant-table-expanded-row td:first-of-type {
			padding: 0 !important;
		}
		&
			.ant-table.ant-table-small
			tbody
			.ant-table-expanded-row
			.package-list-waybill
			.package-data
			td:first-of-type {
			padding: 5px 12px !important;
		}

		// & .ant-table-expanded-row {
		// 	& > td {
		// 		background-color: #fff7f7;
		// 	}
		// 	background-color: #fff7f7;
		// }
	}
	.ant-table.ant-table-small {
		.ant-table-thead > tr > th {
			padding: 6px 12px;
		}

		tbody {
			td {
				&:first-of-type {
					padding-left: 12px !important;
				}

				padding: 5px 16px !important;
				border-bottom: none;
			}
		}
	}

	&-waybill {
		// background-color: #fff7f7;
		.ant-table.ant-table-small .ant-table-tbody {
			// background-color: #fff7f7;
		}
		.ant-table.ant-table-small .ant-table-thead > tr > th {
			// background: #fff7f7;
			&::before {
				width: 0 !important;
			}
		}

		& .ant-table-measure-row {
			// dis
			padding: 0;
			height: 0;
		}
	}
}
.order-transaction-list {
	.ant-table-thead > tr > th {
		padding: 5px 16px;
		background-color: #f2f2f2;
		white-space: nowrap;
	}
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
			[colspan]
		)::before {
		background-color: #c7c7c7;
	}
	.ant-table-tbody > tr > td {
		padding: 9px 16px;
	}
}

.table-expandable {
	& .ant-table table tbody tr td.ant-table-cell.ant-table-row-expand-icon-cell {
		padding-left: 4px;
		padding-right: 4px;
	}
	&.package-list {
		.ant-table.ant-table-small .ant-table-thead > tr {
			border-bottom: none !important ;
			& > th {
				font-family: 'Roboto', sans-serif;
				font-size: 12px;
				line-height: 22px;
				color: #1a1a1a;
				font-weight: 500;
				&:nth-child(2) {
					padding-left: 0 !important;
				}
			}
		}
		.ant-table.ant-table-small .ant-table-tbody > tr {
			& > td:nth-child(2) {
				padding-left: 0 !important;
			}
		}
	}
	.ant-table-expanded-row {
		& > td {
			padding: 0 12px !important;
			& .ant-table {
				margin: 0 !important;
				.ant-table-thead > tr > th.ant-table-cell {
					&::before {
						width: 0;
					}
				}
				td {
					padding: 6px 12px !important;
					// background-color: $bg-color-pink2;
					&::hover {
						background-color: unset;
					}
					border-bottom: none;
				}
			}
		}
	}

	&-bordered {
		.ant-table-expanded-row {
			& > td {
				& .ant-table {
					& .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody {
						& > tr:last-child > td {
							border-bottom: none !important;
						}
						& > tr > td:not(:last-child) {
							border-right: none !important;
							border-bottom: 1px solid #ececec !important;
						}
					}
				}
			}
		}
	}
}
