:root {
  --status-bg: #f6f6f6;
  --status-text: #5a5a5a;
  --status-bg-hover: #ebebeb;
  --status-active-bg: #cd5e77;
  --status-active-text: #fff;
  --expanded-row-bg: transparent;
  --main-bg: #fff;
  --active-row: #fff7f7;
}

$color-secondary: #7d7e7e;
$txt-color-primary: #1a1a1a;

.filter-form {
  background-color: #fff;
  border-radius: 8px;

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-collapse.first-collapse {
    background-color: var(--main-bg);
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .ant-collapse-header {
      flex-direction: row-reverse;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;

      @media screen and (min-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
      }

      .ant-collapse-header-text {
        font-size: 16px;
        font-weight: 500;
      }

      .ant-collapse-arrow {
        font-size: 14px;
        margin: 0;
      }
    }

    > .ant-collapse-item {
      border-radius: 6px;

      > .ant-collapse-content > .ant-collapse-content-box {
        padding: 12px;
        padding-top: 0;

        @media (min-width: 768px) {
          padding: 16px 24px;
          padding-top: 0;
        }
      }
    }
  }

  .ant-collapse.second-collapse {
    width: 100%;

    .ant-collapse-item {
      .ant-collapse-header {
        display: none;
      }
    }

    .ant-collapse-content-box {
      background-color: var(--main-bg);
      padding: 0;
    }
  }

  .status-item {
    font-size: 12px;
    line-height: 20px;
    background-color: var(--status-bg);
    color: var(--status-text);
    padding: 2px 16px;
    font-weight: 500;
    border-radius: 16px;
    border: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: var(--status-bg-hover);
    }

    &.active {
      background-color: var(--status-active-bg);
      color: var(--status-active-text);
    }
  }
}

.table-common {
  background: var(--background-white, #fff);

  &:not(.table-no-container-style) {
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0 12px 12px 12px;
    min-height: 700px;

    @media screen and (min-width: 768px) {
      padding: 0 24px 24px 24px;
    }
  }

  .ant-table-tbody:before {
    color: transparent;
    content: '-';
    display: block;
    height: 8px;
  }

  .ant-pagination {
    li {
      font-size: 14px;
      color: #5a5a5a;

      &.ant-pagination-disabled {
        color: rgb(0 0 0 / 40%);
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      margin: 0;

      button {
        border: none;
      }
    }

    .ant-pagination-item,
    .ant-pagination-jump-prev-custom-icon,
    .ant-pagination-jump-next-custom-icon {
      display: none;
    }

    .ant-pagination-disabled .ant-pagination-item-link {
      background-color: unset;
    }

    .ant-pagination-total-text {
      display: inline-flex;
      align-items: center;
    }
  }
  
  .ant-table-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-table {
    .ant-table-thead > tr > th {
      border-bottom: none;
      background: #f6f6f6;
      color: $color-secondary;
      padding: 8px 12px;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    .ant-table-container {
      table {
        border-collapse: separate;

        > thead {
          > tr:first-child {
            th:first-child {
              border-top-left-radius: 6px;
            }

            th:last-child {
              border-top-right-radius: 6px;
            }
          }
        }

        &.ant-table-row.active-row {
          background-color: var(--expanded-row-bg);
          position: relative;

          &:hover {
            & > td {
              background-color: var(--expanded-row-bg);
            }
          }

          td:nth-child(2) {
            border-radius: unset !important;
          }

          .ant-table-row-expand-icon-cell {
            border-radius: 8px 0 0 0 !important;
            border-left: 1px solid #fcd535;
          }

          td {
            &.ant-table-cell {
              border-top: 1px solid #fcd535;
            }

            &:last-of-type {
              border-radius: 0 8px 0 0 !important;
              border-right: 1px solid #fcd535;
            }
          }
        }

        .ant-table-row-expand-icon-cell {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
        [colspan]
      )::before {
      display: none;
    }

    .ant-table-tbody {
      > tr {
        > td {
          border-color: #f1f1f1;
          padding: 6px 12px;
          vertical-align: middle;

          &.ant-table-cell-row-hover {
            background: var(--active-row) !important;
          }
        }

        .ant-table-row-expand-icon-cell {
          > span {
            @media screen and (max-width: 1281px) {
              font-size: 1px;

              > div {
                width: 8px;
              }
            }
          }

          > span > div {
            width: 12px;
            color: $txt-color-primary;
            padding: 0 0 65px 0;
          }

          > div > i {
            font-weight: 900;
          }
        }
      }

      .ant-table-row {
        td {
          padding: 16px 12px;
          border: none;

          &:first-of-type {
            border-start-start-radius: 12px;
            border-end-start-radius: 12px;
          }

          &:last-of-type {
            border-start-end-radius: 12px;
            border-end-end-radius: 12px;
          }

          &.row-extended {
            padding-top: 0;
          }
        }

        td:first-child {
          padding-left: 16px;
        }

        td:last-child {
          padding-right: 16px;
        }

        @media screen and (min-width: 426px) and (max-width: 768px) {
          td {
            padding: 8px;
          }
        }

        .actions {
          width: 48px;

          button {
            height: 14px;
            line-height: 14px;
          }

          * {
            display: none;
          }
        }

        &:hover {
          .actions {
            * {
              display: inline-block;
            }
          }
        }
      }
    }

    .ant-table-tbody > tr:last-child {
      > td {
        border-bottom: 1px solid transparent;
      }
    }

    &.ant-table-empty {
      .ant-table-body {
        overflow: hidden !important;
      }

      .ant-table-content {
        overflow: auto;
      }

      @media (min-width: 1120px) {
        .ant-table-content {
          overflow: hidden !important;
        }
      }
    }

    tbody {
      tr {
        &.ant-table-row {
          &:focus-visible,
          &:active,
          &:focus {
            outline: none !important;
            border: none !important;
          }
        }
        .ant-table-row-expand-icon-cell {
          border-left: 1px solid transparent;

          > span {
            display: inline-block;
            width: 24px;
          }
        }

        &.ant-table-row:nth-child(even) {
          &:not(.row-light):not(.row-dark) {
            td {
              background-color: var(--expanded-row-bg);
            }
          }
        }

        &.ant-table-row.active-row {
          background-color: var(--expanded-row-bg);
          position: relative;
          td:first-of-type {
            border-end-start-radius: 0;
          }

          td:last-of-type {
            border-end-end-radius: 0;
          }

          &:hover {
            & > td {
              background-color: var(--expanded-row-bg);
            }
          }

          td:nth-child(2) {
            border-radius: unset !important;
          }

          .ant-table-row-expand-icon-cell {
            border-radius: 8px 0 0 0 !important;
            border-left: 1px solid var(--expanded-border-color);
          }

          td {
            &.ant-table-cell {
              border-top: 1px solid var(--expanded-border-color);
            }

            &:last-of-type {
              border-radius: 0 8px 0 0 !important;
              border-right: 1px solid var(--expanded-border-color);
            }
          }
        }

        &.ant-table-expanded-row {
          > td.ant-table-cell {
            border-radius: 0px 0 8px 8px;
            border: 1px solid var(--expanded-border-color);
            border-top: unset;
            padding: 8px 16px 16px 16px;
          }

          .ant-table-wrapper::before {
            display: none;
          }

          .ant-table {
            table {
              background-color: var(--expanded-row-bg);
              border-spacing: 0 4px;

              thead th {
                color: #3340b6;
                font-size: 12px;
                font-weight: 500;
                padding: 6px 12px;
              }
            }
          }
        }
      }

      .ant-table-wrapper::after {
        display: none;
      }
    }

    .ant-table-expanded-row-level-1 > td::before {
      content: '';
      width: calc(100% + 2px);
      height: 4px !important;
      background-color: var(--bg-lighter-2-color);
      position: absolute;
      top: -4px;
      left: -1px;
      border: 1px solid var(--expanded-border-color);
      border-top: none;
      border-bottom: none;
    }
  }

  @media (max-width: 767px) {
    .ant-table-tbody::before {
      display: none;
    }
  }
}

.table-common .ant-table-tbody > .row-dark.ant-table-row:not(.data-row_extended):not(.ant-table-row-level-0):hover {
  & + .row-dark,
  & + .row-light {
    > td {
      background-color: #fff7f7;
    }
  }
}

.table-common .ant-table .ant-table-title,
.table-common .ant-list .ant-list-header {
  padding: 16px 0;
}

// chỉnh cho bảng có phần nới rộng (ds đơn ký gửi)
.table-common.table-has-extended-row {
  .ant-table-tbody:before {
    color: transparent;
    content: '-';
    display: block;
    line-height: 1em;
  }

  .ant-table .ant-table-tbody .ant-table-row td:first-of-type {
    border-end-start-radius: 0;
  }

  .ant-table .ant-table-tbody .ant-table-row td:last-of-type {
    border-end-end-radius: 0;
  }

  td.row-extended {
    border-radius: 0 0 12px 12px !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: unset;
  }
}

// chỉnh cho bảng có thể expand khi click vào dòng (ds yêu cầu giao)
.table-common.table-expandable {
  table {
    border-spacing: 0 2px;

    .ant-table-tbody:before {
      height: 0;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background-color: unset;
    }

    .active-row > td {
      background-color: var(--active-row);
    }

    &.ant-table-expanded-row {
      > td.ant-table-cell {
        background-color: var(--expanded-row-bg);
      }
    }
  }

  .child-table {
    thead th,
    td {
      background-color: transparent !important;

      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }
    }

    tbody td {
      &.ant-table-cell-row-hover {
        background-color: transparent !important;
      }
      &:first-child {
        padding-left: 0 !important;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    }

    .ant-empty.ant-empty-normal {
      margin: 0;
    }
  }
}

.table-common-pagination {
  .ant-pagination-prev {
    margin: 0;
  }
  .ant-pagination-item {
    display: none;
  }

  .ant-pagination-disabled {
    color: #8c8888d9;
  }

  &.icon-hidden {
    .ant-pagination-total-text,
    .ant-pagination-prev,
    .ant-pagination-next {
      display: none;
    }
  }
}

.table-common .ant-list {
  .ant-list-items {
    overflow: auto;

    .ant-list-item {
      padding: 8px 0;
      border: none;
      &:hover {
        background-color: unset;
      }
    }
  }

  .ant-list-item-meta {
    padding: 12px 16px;

    &:hover {
      background-color: #fff7f7;
      border-radius: 6px;
    }
  }
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background-color: transparent;
}
