.text-copy {
	&.dark {
		&.ant-typography {
			color: #e6e6e6;
		}
	}
	&.ant-typography {
		// color: #5a5a5a;
		margin-bottom: 0;
		font-weight: 500;
		white-space: nowrap;
		a,
		span {
			overflow: hidden;
			word-break: break-all;
			white-space: nowrap;
		}
	}
}

