.ant-select-arrow {
  font-size: 14px;
}

.floating-label {
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 26px;
    line-height: 26px;
    font-size: 14px;
  }

  .ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0;
  }
}

.ant-select-dropdown {
  border-radius: 4px;
}