.skeleton-table {
  .skeleton-right {
    .ant-skeleton-paragraph {
      display: flex;
      justify-content: right;
    }
  }

  .ant-table-cell {
    opacity: 0.5;
  }
}
