@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'resources/scss/common/css_variable';
@import 'resources/scss/common/variable';
@import 'resources/scss/mixins/mixins';
@import 'resources/scss/common/mixin';
@import 'resources/scss/common/typography';
@import 'resources/scss/common/_button';
@import 'resources/scss/common/input';
@import 'resources/scss/common/select';
@import 'resources/scss/common/modal';
@import 'resources/scss/common/common';
@import 'resources/scss/common/layout';
@import 'resources/scss/common/table';
@import 'resources/scss/common/_badge';
@import 'resources/scss/common/tabs';
@import 'resources/scss/common/card';
@import 'resources/scss/common/notification';

@import 'resources/scss/screens/access-denied';
@import 'resources/scss/screens/authentication';
@import 'resources/scss/screens/notfound';
@import 'resources/scss/screens/_orders';
@import 'resources/scss/screens/_order';
@import 'resources/scss/screens/_customer';
@import 'resources/scss/screens/_packages';
@import 'resources/scss/screens/package-order';
@import 'resources/scss/screens/preferences';
@import 'resources/scss/screens/shipment';
@import 'resources/scss/screens/shipments';

::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
