.floating-label {
  position: relative;

  .ant-form-item-label,
  .second-label {
    z-index: 2;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 14px;
    transition: all 0.2s;
    font-size: 14px;
    padding: 0 4px !important;
    height: 18px;
    line-height: 18px;
  }

  label {
    display: inline-flex;
    flex-direction: row-reverse;
    gap: 4px;
    transition: all 0.2s;

    &::before {
      margin-right: 0 !important;
    }
  }

  .second-label {
    left: calc(50% + 4px);
  }

  label,
  .second-label {
    color: var(--text-secondary);
    height: 18px;

    &::after {
      display: none;
    }
  }

  &.float {
    .second-label,
    .ant-form-item-label {
      top: -10px;
      transform: scale(0.9);
      transition: all 0.2s;
      background: #fff;
      padding: 0 4px !important;
    }

    .ant-form-item-label {
      left: 10px;
    }
  }

  .ant-form-item-has-error {
    .second-label,
    label {
      color: #cd5e77;
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item.ant-form-item-with-help {
    margin-bottom: 34px;
  }

  .ant-form-item-explain-error {
    margin-top: 2px;
  }
}
