.upload-image {
  .ant-form-item-label {
    display: inline-flex;
    align-items: center;
  }

  .ant-upload-picture-card-wrapper {
    height: 104px;
    width: 100%;
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }

  .ant-progress-text {
    display: none;
  }

  .ant-upload-list-item-actions > a > span {
    padding-top: 4px;
  }

  .ant-upload-list-item-uploading {
    display: flex;
    align-items: center;
  }
  // .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  //   height: 100%;
  // }
}
