.shipments {
  line-height: 20px;
  &-filter {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px 24px;
    box-shadow: 0px 2px 14px #e6e6e6;
    background-color: white;
    .ant-checkbox + span {
      font-size: app-font-sizes(reg);
    }
    .ant-input-affix-wrapper {
      border-radius: 8px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
      height: 38px;
      padding-left: 16px;
    }
    &__title {
      gap: 8px;
      flex-wrap: nowrap;
      & .title {
        & > h3 {
          font-size: app-font-sizes(md);
          white-space: nowrap;
          margin: 0;
        }
        flex-basis: fit-content;
      }

      & .divider {
        & .ant-divider {
          border-top: 1px solid #ebebeb;
        }
        flex-basis: 100%;
      }
    }
    &__statuses {
      display: flex;
      flex-wrap: wrap;
      &-label {
        font-size: 12px;
        margin-right: 4px;
        margin-bottom: 7px;
      }
      & .status {
        border-radius: 20px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5a5a5a;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        background-color: #f6f6f6;
        padding: 2px 16px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        &.active {
          background-color: #cd5e77 !important;
          color: #ffffff !important;
        }
        &:hover {
          background-color: #ebebeb;
          color: #5a5a5a;
        }
      }
    }
    &__form-input {
      padding: 0 !important;
      // &__text-field {
      // 	color: Red;
      // }
      // &-input {
      // 	padding: 0 !important;
      // margin-bottom: 12px;
      // .ant-row {
      // 	margin: 0;
      // }
      // input:not([type='range']) {
      // 	border-radius: 4px;
      // }
      // input[type='range'] {
      // 	border-radius: 2px;
      // }
      // input {
      // 	width: 100%;
      // 	&::placeholder {
      // 		color: $color-app-gray1;
      // 	}
      // }
      // & .form-label {
      // 	font-size: app-font-sizes(reg);
      // 	white-space: nowrap;
      // 	line-height: 20px;
      // }
      // &__text-field{
      //     color:Red
      // }

      // 	&--date {
      // 		display: flex;
      // 		align-items: center;
      // 		gap: 10px;
      // 		&.timestamp > .ant-form-item > .ant-form-item-label {
      // 			flex-basis: 112px;
      // 		}
      // 		.ant-row {
      // 			width: 100%;
      // 			& .ant-picker {
      // 				width: 100%;
      // 				border-radius: 8px;
      // 			}
      // 		}
      // 	}
      // 	& .selection {
      // 		flex-shrink: 0;
      // 		& .ant-select {
      // 			.ant-select-selection-item {
      // 				line-height: 22px;
      // 				display: flex;
      // 				align-items: center;
      // 				& .divider {
      // 					border-left: 2px solid #707070;
      // 				}
      // 			}
      // 		}
      // 	}
      // }
    }
    .actions {
      display: flex;
      height: 32px;
      margin-top: 8px;
      &-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > button {
          border-radius: 4px;
        }
        & .clear {
          margin-right: 12px;
          color: #707070;
          border: none;
          box-shadow: none;
          .anticon {
            transform: rotate(-69deg);
          }
          &:hover {
          }
        }
      }
    }
    .marketplaces {
      font-size: app-font-sizes(reg);
      & .ant-checkbox-group-item {
        margin-right: 30px;
      }
    }
  }
  &-data {
    .ant-pagination-item {
      font-family: "Roboto", sans-serif;
      & > a {
        font-size: app-font-sizes(reg);
      }
      &-active {
        background-color: #cd5e77;
        & > a {
          color: white;
        }
      }
    }
    .ant-pagination-options .ant-select-selection-item {
      font-size: app-font-sizes(reg);
      color: #707070;
    }
    .ant-table-cell {
      vertical-align: middle;
      border-bottom: 12px solid #fff7f7;
      padding: 20px;
    }
    &__info {
      &-text {
        & .code {
          margin: 0 !important;
          font-weight: 500;
          white-space: nowrap;
          color: CD5E77;
          font-size: app-font-sizes(reg);
          line-height: 22px;
        }
        & .divider {
          width: 1px;
          height: 16px;
          background-color: #c7c7c7;
        }
      }
    }

    &__provider {
      line-height: 20px;
      flex-wrap: nowrap;
      & .logo {
        flex-shrink: none;
      }
      & .text {
        white-space: nowrap;
      }
    }

    &__status {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: app-font-sizes(reg);
      line-height: 20px;
      & > i {
        color: #cd5e77;
      }
      & > span {
        padding: 1px 10px;
        border-radius: 25px;
        color: white;
        white-space: nowrap;
      }

      &-button {
        margin-bottom: -24px;

        > span {
          text-decoration: underline;
        }
      }
    }
    .ant-pagination-total-text {
      margin-right: auto;
    }
  }
  // &_list {
  // 	margin-left: 0 !important;
  // 	margin-right: 0 !important;
  // 	// background-color: #cd5e77;
  // }
  &_items {
    background-color: white;
    border-radius: 6px;
    &.ant-list {
      & .ant-list-header {
        border-bottom: none;
        padding: 0 12px;
        padding-left: 0;
      }
      & .ant-list-item {
        align-items: center;
        border-radius: 6px;
        border-bottom: none;
        flex-basis: 85%;
        // margin-bottom: 12px;
        margin-top: 14px;
        & .shipment-status {
          flex-grow: 0;
        }
        &:hover {
          background: #fff7f7;
        }
        padding: 12px 16px;
        & .ant-list-item-meta-title {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 20px;
          font-weight: 400;
          & > div {
            height: 100%;
          }

          & .ant-divider {
            border-left: 1px solid #c7c7c7;
          }
        }

        & .ant-list-item-meta {
          align-items: center;
          &-content {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
.header-list {
  margin-left: 16px;
}

.ant-list-split .ant-list-header {
  border: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #fff7f7;
}

// .ant-table-tbody > tr > td {
// 	border-bottom: none !important;
// }
// .ant-picker-input{width: 350px;}
.shipments-filter__form {
  position: relative;
  margin-top: 10px;
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    // border-color: $secondary-color !important;
    border-right-width: 1px;
    z-index: 1;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: #d98697;
    box-shadow: 0 0 0 2px rgb(205 94 119 / 20%);
    border-right-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper,
  textarea {
    border-radius: 8px !important;
  }

  .form-label {
    position: absolute;
    top: 50%;
    left: 24px;
    font-size: 12px;
    transform: translateY(-50%);
    color: gray;
    user-select: none;
    pointer-events: none;
    transition: 0.25s ease;
    z-index: 10;
  }

  // .form-input {
  // 	padding-top: 10px;
  // 	padding-bottom: 10px;
  // }

  .form-input:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .form-input:not(:placeholder-shown) {
    padding-top: 8px;
    padding-bottom: 8px;
    // padding-left: 16px;
    font-size: 14px;
  }

  // .form-input:not(:placeholder-shown) + .form-label,
  .form-input:focus + .form-label,
  .ant-input-affix-wrapper-focused + .form-label,
  .input-has-value + .form-label {
    font-size: 12px;
    font-weight: 400;
    top: 0px;
    background: #ffff;
    left: 24px;
    // padding: 0px 4px;
  }

  .input-has-value .ant-input-suffix {
    margin-top: -2px;
  }
  .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
    line-height: 2.5 !important;
    padding: 0;
  }
}

@media screen and (max-width: 1024px) {
  .headerList-select-view {
    display: none;
  }
  .end-row {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #e5e5e5;
  }

  .shipments_items.ant-list .ant-list-item {
    padding: 0;
  }
  .header-list {
    margin: 0;
  }
  .shipments_items.ant-list .ant-list-item:hover {
    background: #ffffff;
  }
}

@media screen and (max-width: 767px) {
  .shipments_items {
    background-color: #f0f2f5;
  }
  .bg-white {
    background-color: #f0f2f5 !important;
  }
  .ant-layout {
    background-color: #f0f2f5;
  }

  .row-dark {
    background-color: #ffffff;
  }

  .row-light {
    background-color: #ffffff;
  }

  .shipments_items.ant-list .ant-list-item .ant-list-item-meta {
    padding: 16px;
  }
  .header-list {
    margin: 0;
  }
  .shipments_items {
    padding: 0 !important;
  }
  .shipments_items.ant-list .ant-list-item .ant-list-item-meta-content {
    margin: 0;
  }
  .clean-filter {
    display: none !important;
  }
  .datepicker-range .datepicker-field .ant-picker {
    width: 250px;
  }
  .shipments_items.ant-list .ant-list-item:hover {
    background: #ffffff;
  }
  .shipments-filter__form {
    width: calc(100%);
  }
}

@media screen and (max-width: 600px) {
  .datepicker-range .datepicker-field .ant-picker {
    width: 100px;
  }
}
// .ant-picker-input > input:placeholder-shown {
//     width: 85px;
//     position: absolute;
//     /* text-overflow: ellipsis; */
//     top: -27px;
//     background-color: white;
//     color: red;
//     text-align: center;
// }

.shipments-filter {
  .datepicker-range {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    &:hover {
      border-color: #d98697 !important;
      // box-shadow: 0 0 0 2px RGB(24 144 255/20%);
    }
    &:focus-within {
      border-color: #d98697 !important;
      box-shadow: 0 0 0 2px rgb(205 94 119 / 20%);
      outline: 0;
    }
    .datepicker-field {
      position: relative;

      .form-label {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);

        user-select: none;
        pointer-events: none;
        transition: 0.25s ease;
      }

      .ant-picker {
        padding-top: 9px;
        padding-bottom: 9px;
        width: 100%;
        &:focus,
        &:focus-within,
        &.input-has-value {
          padding-top: 9px;
          padding-bottom: 9px;

          .ant-picker-suffix {
            margin-top: -3px;
          }
        }

        .ant-picker-input input:not(:placeholder-shown) + .form-label,
        &:focus-within + .form-label,
        &.input-has-value + .form-label {
          font-size: 12px;
          font-weight: 400;
          top: 0px;
          background: #ffff;
          padding: 0px 4px;
        }

        &.input-has-value .ant-picker-clear {
          margin-top: -1px;
        }
      }
    }
    .ant-picker-input > input {
      // font-size: 14px;
    }

    .ant-select {
      position: relative;
    }

    .selected-label {
      position: absolute;
      top: 1px;
      font-size: 12px;
      // transform: translateY(-50%);
      color: gray;
      // user-select: none;
      // pointer-events: none;
      // transition: 0.25s ease;
      // z-index: 10;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      .selected-label {
        position: absolute;
        top: -8px;
        margin: 0;
        padding: 0;
        background-color: white;
        font-size: 12px;
        transform: translateY(-50%);
        color: gray;
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
        transition: 0.25s ease;
        z-index: 10;
        height: 20px;
      }
    }
    .ant-select-selection-placeholder {
      visibility: unset !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      line-height: 35px !important;
      // padding-left: 10px;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      padding-left: 8px;
    }

    .row-extended {
      padding-top: 0px !important;
    }

    .ant-pagination-item {
      border-radius: 6px;
    }
    .ant-btn {
      height: 36px;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 4px;
    padding-right: 1px;
}
}
