/* Color*/
$color-base: #666;
$color-secondary: #222;
$color-text: #666;
$color-text-secondary: #888;
$color-text-heading: #222;
$color-link: #3498db;
$color-bg-footer: #282828;

/* Variable Template, App Layout */

$color-app-base: #1a1a1a;
$color-app-primary: #2a9f9c;
$color-app-primary-m: #22817e;
$color-app-tertiary: #e2fbfa;
$color-app-secondary: #f4f6f8;
$color-app-border: #34c4c0;
$color-app-disabled: #bdbdbd;
$color-app-base-input: #d9d9d9;
$color-app-link: #008df9;
$color-app-modal-border: #c7c7c7;
$color-app-placeholder: #bdbdbd;
$color-app-heading-sub: #707070;
$color-app-white: #fff;
$color-app-gray: #c7c7c7;
$color-app-gray1: #bdbdbd;
$color-app-gray2: #707070;
$color-app-gray3: #ebebeb;
$color-app-gray4: #f5f5f5;
$color-app-gray5: #e5e5e5;
$color-app-orange: #ffa634;
$color-app-red: #ff4559;
$color-app-6: #a6a6a6;
$color-app-blue: #4674c1;
$color-app-orange-1: #f4b171;
$color-app-black: #2b2424;
$color-app-pink: #ffe4e6;
//**************************

$darkSkyBlue: #3498db;
$line: #cbcacf;
$title: #333;
$jadeGreen: #27ae60;
$brownishGrey: #666;
$bloodOrange: #ff4400;
$tomato: #e74c3c;
$btn: #adadad;

/*//Fonts-size*/
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 32px;

/*//Url base*/
$url-img: '../../images';

/*//Breakpoint*/
$bp-xs: 320px;
$bp-mobile: 320px;

$bp-s: 480px;
$bp-sm: 768px;
$bp-tablet: 768px;

$bp-m: 992px;
$bp-l: 1200px;
$bp-xl: 1440px;

/*// Contain floats / clearfix*/

%clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* New style 2018-03-08 */

/* Variable Template, App Layout */
$color-app-base: #1a1a1a;
$color-app-primary: #2a9f9c;
$color-app-primary-m: #22817e;
$color-app-tertiary: #e2fbfa;
$color-app-secondary: #f4f6f8;
$color-app-border: #34c4c0;
$color-app-disabled: #bdbdbd;
$color-app-base-input: #d9d9d9;
$color-app-link: #008df9;
$color-app-modal-border: #c7c7c7;
$color-app-placeholder: #bdbdbd;
$color-app-heading-sub: #707070;
$color-app-violet: #cd5e77;
$color-app-violet-light: #fff7f7;
$color-app-violet-light1: #f4dcdf;
$color-app-violet-light2: #ffe4e6;
$color-app-violet-light3: #eba7ac;
$base-color: #00abf3;
$base-text-color: #fff;
$color-app-green: #09b8af;

$font-weights: (
  'thin': 100,
  'light': 300,
  'regular': 400,
  'medium': 500
);

@function font-weights($style) {
  @return map-get($font-weights, $style);
}

$app-font-sizes: (
  'sm': 12px,
  'reg': 14px,
  'md': 16px,
  'lg': 24px,
  'heading': 20px
);

@function app-font-sizes($style) {
  @return map-get($app-font-sizes, $style);
}

$app-button-colors: (
  'selected': $color-app-tertiary,
  'clicked': $color-app-secondary,
  'hover': $color-app-primary-m
);

@function app-button-colors($style) {
  @return map-get($app-button-colors, $style);
}

$app-text-colors: (
  'base': $color-app-base,
  'primary': $color-app-primary,
  'secondary': $color-app-placeholder,
  'desc': $color-app-heading-sub,
  'badge': $color-app-tertiary
);

@function app-text-colors($style) {
  @return map-get($app-text-colors, $style);
}
$primary-color: #cd5e77;
$primary-color-hover: #ffd3d8;
$menu-item-parent-color: #bdbdbd;
$box-shadow-color: #ced1f3;
$box-shadow-color1: #e6e6e6;
$box-shadow-color: #ced1f3;
:root {
  --primary-color: #cd5e77;
}

//table
$table-header-bg: #f2f2f2;
$bg-color-gray-tab: #f4f6f8;

//Font Family
$robotofont: 'Roboto', sans-serif;

//Font Size
$txt-size-h1: 24px;
$txt-size-h2: 22px;
$txt-size-h3: 20px;
$txt-size-h4: 18px;
$txt-size-h5: 16px;
$txt-size-h6: 15px;
$txt-size-h7: 14px;
$txt-size-h8: 12px;
$txt-size-h9: 10px;
$txt-size-32: 32px;
//Color
$txt-color-white: #ffffff;
$txt-color-black2: #333333;
$txt-color-black3: #363636;
$txt-color-black: #1a1a1a;
$txt-color-secondary: #707070;
$txt-color-gray: rgba(0, 0, 0, 0.45);
$txt-color-gray2: #bdbdbd;
$txt-color-gray3: #f2f2f2;
$txt-color-gray4: #707070;
$txt-color-gray6: #c7c7c7;
$txt-color-gray7: #7d7e7e;
$txt-color-gray5: #ebebeb;
$txt-color-gray8: #5a5a5a;
$txt-color-red: #f5222d;
$txt-color-red2: #ff4559;
$txt-color-red3: #e63d4f;
$txt-color-red4: #bf3443;
$txt-color-orange: #f59f32;
$txt-color-orange1: #fbb739;
$txt-color-orange2: #fcd535;
$txt-color-orange3: #faa200;
$txt-color-orange4: #fdb924;
$txt-color-orange5: #ffeece;
$txt-color-orange6: #fcd535;
$txt-color-orange7: #fff9df;
$txt-color-blue: #096dd9;
$txt-color-blue1: #303a41;
$txt-color-blue2: #001a4b;
$txt-color-blue3: #0082e5;
$txt-color-blue5: #09b8af;
$txt-color-blue6: #3340b6;
$txt-color-blue7: #008df9;
$txt-color-blue8: #339dff;
$txt-color-green: #09b2aa;
$txt-color-green1: #43b082;
$txt-color-only-staff: #0f98b1;
$txt-color-primary: #f59f32;
$txt-color-order-detail: #f9a400;
$txt-color-order-finance: #fff7ee;
$txt-color-pink4: #f54255;

//Background Color
$bg-color-primary: #fcd535;
$bg-color-white: #ffffff;
$bg-color-yellow: #fffbe6;
$bg-color-blue: #096dd9;
$bg-color-blue2: #e6f7ff;
$bg-color-blue3: #a6dbff;
$bg-color-gray: #f0f2f5;
$bg-color-gray2: #d9d9d9;
$bg-color-gray3: #f2f2f2;
$bg-color-gray4: #707070;
$bg-color-gray7: #bdbdbd;
$bg-color-gray5: #ebebeb;
$bg-color-gray6: #c7c7c7;
$bg-color-gray-badge: #f5f5f5;
$bg-color-gray-tab: #f4f6f8;
$bg-color-dark: #011d57;
$bg-color-green: #09b2aa;
$bg-color-black: #000000;
$bg-color-orange: #ffedef;
$bg-color-orange1: #fbb739;
$bg-color-orange2: #ffd7a5;
$bg-color-orange3: #faa200;
$bg-color-orange4: #ffeece;
$bg-color-orange5: #fff9df;

$bg-color-blue4: #ddf0ff;
$bg-color-blue5: #09b8af;
$bg-color-blue6: #e0f9f7;
$bg-color-blue7: #3340b6;
$bg-color-pink: #fff7ee;
$bg-color-pink1: #ffeed9;
$bg-color-pink2: #fff6e5;
$bg-color-pink4: #f54255;
$bg-color-pink5: #ffedef;
$bg-color-pink6: #feedde;

/***** guide-line *****/

//border
$bd-disabled: #c7c7c7;
$bd-warning: #ff4559;
$txt-warning: #ff4559;
$bd-divider-success-box: #ffa600;
/***** guide-line *****/
// bg
$bg-warning: #ff4559;
$bg-disabled: #f5f5f5;
$bg-secondary-gray: #ecedf8;

//box-shadow

$box-sd-primary: #ffe3c0;
$box-sd-secondary: #ced1f3;
$box-sd-warning: #ffb2ba;
$bg-color-gray8: #edf3f7;
$bg-color-orange2: #fcd535;

//Border Color
