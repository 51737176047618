@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --border-radius: 8px;
}

.root {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

::-moz-selection { /* Code for Firefox */
  color: #FFF;
  background: #498af4;
}

::selection {
  color: #FFF;
  background: #498af4;
}