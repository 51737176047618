:root {
  --expanded-border-color: #cd5e77;
}

.ant-table {
  .ant-table-content {
    .ant-table-thead {
      > tr > th.ant-table-cell {
        color: #707070;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
      }
    }
  }
}

.table-improved {
  & .ant-table-content {
    & .ant-table-thead {
      & > tr > th {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        font-weight: 500;
        border-bottom: none;
        background-color: #f4f4f4;

        &::before {
          width: 0 !important;
        }

        &:first-of-type {
          border-top-left-radius: 6px !important;
        }

        &:last-of-type {
          border-top-right-radius: 6px !important;
        }
      }
    }

    & .ant-table-tbody {
      &::before {
        content: '-';
        display: block;
        line-height: 1em;
        color: transparent;
      }

      & > tr > td {
        padding-left: 12px;
        vertical-align: middle;
        border-bottom: none;
        padding-bottom: 12px;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      & .ant-table-expanded-row {
        & > td {
          padding: 17px 50px;
          background-color: $bg-color-pink6;
          border-bottom: 0 !important;

          &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }

      & .active-row {
        border-radius: 8px !important;

        & > td {
          border-bottom: 0 !important;
        }
      }
    }

    .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover > td {
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover + tr > td {
      background-color: #fff7f7 !important;

      & > i {
        color: $txt-color-red;
      }
    }

    tr.ant-table-row.data-row_extended:hover > td {
      background-color: unset;
    }
  }

  & .ant-table {
    border-radius: 6px;
    padding: 0 16px 16px 16px;

    & .ant-table-title {
      padding-left: 0;
      padding-right: 0;
    }

    & .ant-table-content {
      // padding: 0 16px;
    }
  }
}

.ant-table-row {
  .row-extended {
    padding-top: 0;
  }
}

.table-lastmilebag {
  & .ant-table-content {
    & .ant-table-thead {
      & > tr > th {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        font-weight: 500;
        border-bottom: none;
        background-color: #f4f4f4;

        &::before {
          width: 0 !important;
        }

        &:first-of-type {
          border-top-left-radius: 6px !important;
        }

        &:last-of-type {
          border-top-right-radius: 6px !important;
        }
      }
    }

    & .ant-table-tbody {
      &::before {
        content: '-';
        display: block;
        line-height: 1em;
        color: transparent;
      }

      & > tr > td {
        padding-left: 12px;
        vertical-align: middle;
        border-bottom: none;
        padding-bottom: 12px;

        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      & .ant-table-expanded-row {
        & > td {
          padding: 17px 50px;
          background-color: $bg-color-pink6;
          border-bottom: 0 !important;

          &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        & .shipment-expand-row {
        }
      }

      & .active-row {
        border-radius: 8px !important;

        & > td {
          border-bottom: 0 !important;
        }
      }
    }

    .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover > td {
      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover + tr > td {
      background-color: #fff7f7 !important;

      & > i {
        color: $txt-color-red;
      }
    }

    tr.ant-table-row.data-row_extended:hover > td {
      background-color: unset;
    }
  }

  & .ant-table-row:nt {
    background-color: red;
  }

  & .ant-table {
    border-radius: 6px;
    padding: 0 16px 16px 16px;

    & .ant-table-title {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.ant-table-small {
  .ant-table-thead > tr > th.ant-table-cell {
    font-size: 12px;
  }
}

.ant-table-cell-ellipsis {
  max-width: 40vw;
}