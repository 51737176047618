.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  display: none;
}

.ant-tabs-nav-list {
  border-bottom: 1px solid #f0f0f0;
}

.ant-tabs-nav {
  gap: 12px;
	z-index: 4;
}

.ant-tabs-extra-content {
  z-index: 99;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
	z-index: 0;
  overflow: scroll;

  @media screen and (min-width: 767px) {
    overflow: hidden;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 767px) {
	.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 16px;
	}
}
